import axios from "axios";

const baseAPI = process.env.REACT_APP_BASE_API_URL
const cardsAPI = axios.create({ baseURL: `${baseAPI}api/funil/cards` })


async function getCards(token) {
    const authToken = `Token ${token}`
    const resp = await cardsAPI.get('/', { headers: { Authorization: authToken }})
    return resp.data
}


async function newCard(token, dados) {
    const authToken = `Token ${token}`
    const resp = await cardsAPI.post('/', dados, {headers: { Authorization: authToken, "Content-Type": "application/json" }})
    return resp.data
}


async function updCard(token, dados) {
    const authToken = `Token ${token}`
    const resp = await cardsAPI.put(`/${dados.id}`, dados, {headers: { Authorization: authToken, "Content-Type": "application/json" }})
    return resp.data
}


async function delCard(pk, token) {
    const authToken = `Token ${token}`
    const resp = await cardsAPI.delete(`/${pk}`, {headers: { Authorization: authToken }})
    return resp.data
}


async function fileCardUpload(dados, token) {
    const authToken = `Token ${token}`
    const resp = await cardsAPI.post('/upload/', dados, {headers: { Authorization: authToken, "Content-Type": "multipart/form-data" }})
    return resp.data
}


async function getCardHistory(pk, token) {
    const authToken = `Token ${token}`
    const resp = await cardsAPI.get(`/history/${pk}`, {headers: { Authorization: authToken }})
    return resp.data
}


export { getCards, newCard, updCard, delCard, fileCardUpload, getCardHistory }
