import axios from "axios";


const baseAPI = process.env.REACT_APP_BASE_API_URL
const templateAPI = axios.create({ baseURL: `${baseAPI}api/funil/templatesfunil` })
const templateListaAPI = axios.create({ baseURL: `${baseAPI}api/funil/templateslistasfunil`})


async function getTemplates(token) {
    const authToken = `Token ${token}`
    const resp = await templateAPI.get('/', { headers: { Authorization: authToken } })
    return resp.data
}

async function addTemplates(token, data) {
    const authToken = `Token ${token}`
    const resp = await templateAPI.post('/', data, { headers: { Authorization: authToken, "Content-Type": "application/json" } })
    return resp.data
}

async function getListas(token) {
    const authToken = `Token ${token}`
    const resp = await templateListaAPI.get('/', { headers: { Authorization: authToken } })
    return resp.data
}

async function getLista(token, pk) {
    const authToken = `Token ${token}`
    const resp = await templateListaAPI.get(`/${pk}/`, { headers: { Authorization: authToken } })
    return resp.data
}


export { getTemplates, getListas, getLista, addTemplates }
