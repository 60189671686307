import axios from "axios";

const baseAPI = process.env.REACT_APP_BASE_API_URL
const produtoAPI = axios.create({ baseURL: `${baseAPI}api/funil/produtos` })

async function getProdutos(token) {

    const authToken = `Token ${token}`
    // console.log('authToken: ', authToken)
    const resp = await produtoAPI.get('/', {headers: { Authorization: authToken }})

    return resp.data
}

async function getProduto(token, id) {
    const authToken = `Token ${token}`
    const resp = await produtoAPI.get(`/${id}`, {headers: { Authorization: authToken }})
    return resp.data
}

async function newProduto(dados, token) {
    const authToken = `Token ${token}`
    const resp = await produtoAPI.post('/', dados, {headers: { Authorization: authToken, "Content-Type": "application/json"}})
    return resp.data
}

async function updProduto(dados, token) {
    const authToken = `Token ${token}`
    const resp = await produtoAPI.put(`/${dados.id}`, dados, {headers: { Authorization: authToken, "Content-Type": "application/json"}})
    return resp.data
}

async function delProduto(pk, token) {
    const authToken = `Token ${token}`
    const resp = await produtoAPI.delete(`/${pk}`, {headers: { Authorization: authToken }})
    return resp.data
}

export {
    getProdutos, newProduto, updProduto, delProduto, getProduto
}
