import { Box, Paper, Typography } from "@mui/material";
import React from "react";

export default function Produto({ produto, perfil, produtoClick }) {

    const handleClick = () => {
        produtoClick(produto)
    }

    return (
        <Box 
            component={Paper} 
            elevation={3} 
            sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'center', 
                alignItems: 'center', 
                border: "solid 1px", 
                borderRadius: "10px",
                minWidth: 180, 
                minHeight: 180, 
                maxWidth: 205, 
                maxHeight: 205, 
                margin: 1, 
                padding: 1,
                "&:hover": {cursor: "pointer"}
            }}
            onClick={handleClick}
        >
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                {produto.tag}
            </Typography>
            <Typography variant="subtitle1" sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                {produto.nome}
            </Typography>
            <Typography variant="caption">
                {produto.cards.length} cartões
            </Typography>
        </Box>
    )
}