import { useEffect, useState } from "react"
import { getTemplates } from "../services/SrvFunil"
import HomeBar from "../components/HomeBar/HomeBar"
import { Container, Fab } from "@mui/material"
import TemplateFunil from "../components/TemplateFunil/TemplateFunil"
import { useLocation } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add'
import TemplateFunilForm from "../components/TemplateFunil/TemplateFunilForm"

export default function Funis() {
    
    const [templates, setTemplates] = useState([])
    const [formVisible, setFormVisible] = useState(false)

    var nome = null
    var token = null
    var profile = null
    var isAuthenticated = false

    const location = useLocation()
    const { state } = location

    if (state) {
        nome = state.nome
        token = state.token
        profile = state.profile
        isAuthenticated = state.isAuthenticated
    }

    const fetchTemplates = async () => {
        await getTemplates(token).then(
            (resp) => {
                setTemplates(resp)
            },
            (erro) => {
                alert('Erro ao recuperar templates: ', erro)
            }
        )
    }

    useEffect(() => {
        fetchTemplates()
    }, [])

    const handleAddClick = () => {
        setFormVisible(true)
    }

    const handleClose = () => {
        setFormVisible(false)
    }

    return (
        <>
            <HomeBar nome={nome} token={token} profile={profile} isAuthenticated={isAuthenticated} />
            <Container sx={{ marginTop: 5, paddingTop: '69px' }}>
                {
                    templates.map((template, index) => (
                        <TemplateFunil template={template} key={index} token={token} />
                    ))
                }
                <Fab color="primary" aria-label="add" size="medium" sx={{ mt: 2 }} onClick={handleAddClick}>
                    <AddIcon />
                </Fab>
                <TemplateFunilForm visible={formVisible} handleClose={handleClose} token={token} fetchTemplates={fetchTemplates} />
            </Container>
        </>
    )
}
