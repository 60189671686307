import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import TemplateListaSelect from "./TemplateListaSelect";
import { addTemplates, getListas } from "../../services/SrvFunil";

export default function TemplateFunilForm({ template, visible, handleClose, token, fetchTemplates }) {

    const [formValues, setFormValues] = useState({
        nome: template ? template.nome : '',
        listas: template ? template.listas.map(lista => { return { "id": lista.id, "nome": lista.nome } }) : []
    })

    const [options, setOptions] = useState([])

    // Lista completa de opções
    async function fetchListas() {
        const resposta = await getListas(token).then(
            (resp) => {
                const listas = resp.map(lista => {
                    return { "id": lista.id, "nome": lista.nome }
                })
                setOptions(listas)
            },
            (erro) => {
                alert('Erro ao recuperar listas: ', erro)
            }
        )
        return resposta
    }

    useEffect(() => {
        fetchListas()
    }, [])

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    return (
        <>
            {
                visible && (
                    <Dialog
                        open={visible}
                        onClose={handleClose}
                        PaperProps={{
                            component: 'form',
                            onSubmit: async (event) => {
                                event.preventDefault();
                                const formData = new FormData(event.currentTarget);
                                const formJson = Object.fromEntries(formData.entries());
                                console.log('formJson no submit - antes: ', formJson);
                                var listas = formJson['listas']
                                listas = listas.split(',')
                                formJson['listas'] = listas
                                console.log('formJson no submit - depois: ', formJson);
                                if (!template) {
                                    await addTemplates(token, formJson).then(
                                        (resp) => {
                                            alert('Template salvo com sucesso!')
                                            fetchTemplates()
                                            setFormValues({
                                                nome: "",
                                                listas: []
                                            })
                                            handleClose()
                                        },
                                        (erro) => {
                                            alert('Ocorreu um erro ao tentar salvar o template: ', erro)
                                        }
                                    )
                                }
                            },
                        }}
                    >
                        <DialogTitle>{template ? template.nome : "Novo Funil"}</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Nome"
                                id="nome"
                                name="nome"
                                variant="outlined"
                                value={formValues.nome}
                                onChange={handleFormChange}
                                fullWidth
                                required
                                sx={{ mb: 2 }}
                            />
                            <TextField
                                label="Selecione listas"
                                id="listas"
                                name="listas"
                                select
                                fullWidth
                                required
                                value={formValues.listas}
                                onChange={handleFormChange}
                                SelectProps={{
                                    multiple: true,
                                    renderValue: (selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((id) => {
                                                const option = options.find((opt) => opt.id === id);
                                                return option ? <Chip key={id} label={option.nome} /> : null;
                                            })}
                                        </Box>
                                    ),
                                }}
                            >
                                {options.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.nome}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {/* <TemplateListaSelect id="listas" name="listas" token={token} onChange={handleFormChange} sx={{ m: 2 }}/> */}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Cancelar</Button>
                            <Button type="submit">Salvar</Button>
                        </DialogActions>
                    </Dialog>
                )
            }
        </>
    )
}