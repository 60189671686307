import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@mui/lab";
import { Box, Button, List, ListItem, ListItemText, Modal, Typography } from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DataGrid } from "@mui/x-data-grid";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import { getCardHistory } from "../../services/SrvCard";

const style = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    m: 2,
}

const columns = [
    { field: "field", headerName: "Campo", editable: false },
    { field: "old", headerName: "Valor anterior", editable: false },
    { field: "new", headerName: "Novo valor", editable: false }
]

export default function CartaoDlg({ cartao, token, open, handleClose }) {

    const [historico, setHistorico] = useState([])

    async function getHistorico() {
        await getCardHistory(cartao.id, token).then(
            (resp) => {
                setHistorico(resp)
            },
            (erro) => {
                alert(`Erro ao recuperar o histórico de alterações do card: ${erro}`)
            }
        )
    }

    useEffect(() => {
        // eslint-disable-next-line
        getHistorico()
    }, [open])

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString(); // Formato local padrão de data e hora
    };

    return (
        <Modal
            open={open}
        >
            {/* <Box sx={{ backgroundColor: 'lightyellow', display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center', justifyContent: 'center', height: '100vh', overflow: 'auto', padding: '1px', margin: '2px' }}> */}
            <Box sx={style}>
                {
                    (historico.length > 0) && (
                        <Timeline position="alternate">
                            {
                                historico.map((hist, index) => (
                                    <TimelineItem key={index}>
                                        <TimelineOppositeContent>
                                            <Typography variant="body2" color="textSecondary">
                                                {formatDate(hist.history_date)}
                                            </Typography>
                                        </TimelineOppositeContent>
                                        <TimelineSeparator>
                                            <TimelineDot color="primary">
                                                <AccessTimeIcon />
                                            </TimelineDot>
                                            <TimelineConnector />
                                        </TimelineSeparator>
                                        <TimelineContent>
                                            {
                                                (hist.history_type === 'Created') ? (
                                                    <Typography variant="body1" color="textPrimary">
                                                        O card foi criado.
                                                    </Typography>
                                                ) : (
                                                    (hist.history_type === 'Changed') ? (
                                                        <>
                                                            <Typography variant="body1" color="textPrimary">
                                                                O card foi modificado.
                                                            </Typography>
                                                            {
                                                                <List>
                                                                    {
                                                                        hist.changed_fields.map((chg, index) => (
                                                                            (chg.field !== 'modificado') && (
                                                                                <ListItem key={index}>
                                                                                    <ListItemText primary={chg.field} secondary={`mudou de ${chg.old} para ${chg.new}`} />
                                                                                </ListItem>
                                                                            )
                                                                        ))
                                                                    }
                                                                </List>
                                                            }
                                                        </>
                                                    ) : (
                                                        <Typography variant="body1" color="textPrimary">
                                                            O card foi excluído.
                                                        </Typography>
                                                    )
                                                )
                                            }
                                            {/* <DataGrid
                                                rows={hist.changed_fields}
                                                columns={columns}
                                                getRowId={(row) => row.field}
                                                pageSize={5}
                                            /> */}
                                        </TimelineContent>
                                    </TimelineItem>
                                ))
                            }
                        </Timeline>
                    )
                }
                {
                    (historico.length === 0) && (
                        <Typography>
                            Nada para ver por aqui ainda!
                        </Typography>
                    )
                }
                <Button variant="contained" endIcon={<CloseIcon />} onClick={handleClose}>
                    Fechar
                </Button>
            </Box>
        </Modal>
    )
}