import { Box, Fab, Paper, Step, StepLabel, Stepper, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from "react";
import { getLista } from "../../services/SrvFunil";
import TemplateFunilForm from "./TemplateFunilForm";

export default function TemplateFunil({ template, token }) {

    const [data, setData] = useState({})
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [listas, setListas] = useState(template.listas)
    const [visible, setVisible] = useState(false)

    const fetchListas = async () => {
        try {
            setLoading(true);
            setError(null);

            // Faz uma chamada para cada ID usando Promise.all
            // console.log('listas: ', listas)
            const responses = await Promise.all(
                listas.map(id =>
                    getLista(token, id)
                )
            );

            // Transforma as respostas em um objeto onde a chave é o ID
            const result = responses.reduce((acc, response, index) => {
                // console.log('response: ', response)
                acc[listas[index]] = response;
                return acc;
            }, {});

            // console.log('result: ', result)
            setData(result);
        } catch (err) {
            setError('Ocorreu um erro ao buscar os dados');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchListas()
    }, [listas])

    const sortedData = Object.entries(data)
    .sort(([, objA], [, objB]) => {
      if (objA.ordem < objB.ordem) return -1;
      if (objA.ordem > objB.ordem) return 1;
      return 0;
    });

    const handleUpdClick = () => {
        setVisible(true)
    }

    const handleClose = () => {
        setVisible(false)
    }

    return (
        <>
            <Box component={Paper} elevation={2} sx={{ display: "flex", flexDirection: "column", minWidth: 375, margin: 2, padding: 2 }}>
                <Typography variant="h6" fontWeight="bold">
                    {`Funil: ${template.nome}`}
                </Typography>
                <Stepper sx={{ mt: 2 }}>
                    {
                        sortedData.map(([id, obj]) => (
                            <Step key={id}>
                                <StepLabel>{obj.nome}</StepLabel>
                            </Step>
                        ))
                    }
                </Stepper>
                <Box display="flex" flexDirection="row" flexGrow={1} sx={{ justifyContent: "end", marginTop: 1, marginBottom: 1 }}>
                    <Box sx={{ padding: "0.5rem" }} >
                        <Fab color="secondary" size="small" aria-label="edit" sx={{ padding: 1 }} onClick={handleUpdClick}>
                            <EditIcon />
                        </Fab>
                    </Box>
                    <Box sx={{ padding: "0.5rem" }}>
                        <Fab color="danger" size="small" aria-label="delete" sx={{ padding: 1 }}>
                            <DeleteIcon />
                        </Fab>
                    </Box>
                </Box>
                <TemplateFunilForm template={template} token={token} visible={visible} handleClose={handleClose} />
            </Box>
        </>
    )
}
