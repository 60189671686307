import { Box, Fab, Tooltip, Typography } from "@mui/material";
import Produto from "./Produto";
import AddIcon from '@mui/icons-material/Add';
import { getProdutos } from "../../services/srvProduto";
import { useEffect, useState } from "react";
import ProdutoDlg from "./ProdutoDlg";

export default function ProdutoLista({ perfil, chave }) {

    const [produtos, setProdutos] = useState([])
    const [dlgProdutoShow, setDlgProdutoShow] = useState(false)
    const [produtoSelecionado, setProdutoSelecionado] = useState(null)


    useEffect(() => {
        fetchProdutos()
        // eslint-disable-next-line
    }, [])

    async function fetchProdutos() {
        await getProdutos(chave).then(
            (response) => {
                setProdutos(response)
            },
            (error) => {
                alert('Erro ao recuperar produtos: ', error)
            }
        )
    }

    const addProdutoClick = () => {
        setProdutoSelecionado(null)
        setDlgProdutoShow(true)
    }

    const handleDlgProdutoClose = () => {
        setDlgProdutoShow(false)
    }

    const handleProdutoClick = (produtoClicado) => {
        setProdutoSelecionado(produtoClicado)
        setDlgProdutoShow(true)
    }

    return (
        <Box flexGrow={1} p={2} sx={{ alignContent: 'center', alignItems: 'center', backgroundColor: '#f0e6fb', display: 'flex', flexDirection: 'column', height: "100vh" }}>
            {

                (produtos.length === 0) ? (
                    <Typography variant='h4'>
                        Pelo visto, você ainda não cadastrou nenhum produto. Vamos comecar?
                    </Typography>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'space-around', maxWidth: "85%" }}>
                        {
                            produtos.map(
                                (produto) => (
                                    <Produto key={produto.id} produto={produto} perfil={perfil} produtoClick={handleProdutoClick}/>
                                )
                            )
                        }
                    </Box>
                )
            }
            <Box sx={{ height: 80, width: 80, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                <Tooltip title="Clique para adicionar um produto" placement='top'>
                    <Fab color="primary" sx={{ width: '100%', height: '100%' }} onClick={addProdutoClick}>
                        <AddIcon fontSize='large' sx={{ width: '100%', height: '100%' }} />
                    </Fab>
                </Tooltip>
            </Box>
            <ProdutoDlg produto={produtoSelecionado} perfil={perfil} chave={chave} show={dlgProdutoShow} handleClose={handleDlgProdutoClose} fetchProdutos={fetchProdutos}/>
        </Box>
    )
}