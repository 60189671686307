import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import CommentIcon from '@mui/icons-material/Comment';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Link, List, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../routes/Funil";
import { delCard, fileCardUpload, updCard } from "../../services/SrvCard";
import { getContato } from "../../services/SrvContato";
import CardDateTimeIndicator from "../CardDateTimeIndicator";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import StarRating from '../StarRating';
import WhatsAppLink from '../WhatsAppLink';
import CurrencyTextField from '../CurrencyTextField';
import CurrencyEditableLabel from '../CurrencyEditableLabel';
import CheckListAbordagem from '../CheckListAbordagem';
import { useNotifications } from '../Notifications/NotificationProvider';
import FileUpload from '../FileUpload';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InfoIcon from '@mui/icons-material/Info';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { getProduto } from '../../services/srvProduto';
import CartaoDlg from './CartaoDlg';

const Cartao = ({ cartao, index, lista, token, fetchListas }) => {

    const [contato, setContato] = useState(null)
    const [produto, setProduto] = useState(null)
    const [showTextField, setShowTextField] = useState(false);
    const [observacoes, setObservacoes] = useState(cartao.observacoes ? cartao.observacoes : '')
    const [dataExpiracao, setDataExpiracao] = useState(new Date(cartao.data_expiracao))
    // eslint-disable-next-line
    const [dataLista, setDataLista] = useState(new Date(cartao.data_lista))
    const [rating, setRating] = useState(cartao.rating)
    const [abordagens, setAbordagens] = useState(cartao.abordagens)
    // const [mouseOver, setMouseOver] = useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [atrasado, setAtrasado] = useState(false)
    const [atencao, setAtencao] = useState(false)
    const [valorContrato, setValorContrato] = useState(cartao.valor_contrato)

    // Para o upload de arquivos
    const [showUploadComponent, setShowUploadComponent] = useState(false)
    const baseAPI = process.env.REACT_APP_BASE_API_URL
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [message, setMessage] = useState('');
    const [error, setError] = useState(false);

    const [cartaoDlgOpen, setCartaoDlgOpen] = useState(false)

    const theme = useTheme()
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

    const { addNotification } = useNotifications()

    const [, ref] = useDrag({
        type: ItemTypes.CARD,
        item: { ...cartao, index, lista },
    });

    async function fetchContato() {
        await getContato(token, cartao.contato)
            .then(
                (response) => {
                    setContato(response)
                },
                (erro) => alert(`Erro ao recuperar o contato do card: ${erro}`)
            )
    }

    async function fetchProduto() {
        if (cartao.produto) {
            await getProduto(token, cartao.produto).then(
                (response) => {
                    setProduto(response)
                },
                (erro) => alert('Erro ao recuperar o produto do card: ', erro)
            )
        }
    }

    useEffect(() => {
        fetchContato()
        fetchProduto()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (contato) {
            const interval = setInterval(() => {
                // const dataCard = new Date(cartao.data_lista)
                // const dataExpCard = new Date(cartao.data_expiracao)
                const dataAtual = new Date()

                // const difDatasMs = dataExpCard - dataAtual
                const difDatasMs = dataExpiracao - dataAtual

                if (difDatasMs < 0) {
                    if (!atrasado) {
                        setAtrasado(true)
                        addNotification('Ação atrasada!', contato.nome)
                    }
                } else {
                    setAtrasado(false)
                    // var difTotal = dataExpCard - dataCard
                    var difTotal = dataExpiracao - dataLista
                    var perc_dif = difDatasMs / difTotal
                    // console.log('perc_dif: ', perc_dif)
                    if (perc_dif <= 0.2) {
                        if (!atencao) {
                            setAtencao(true)
                            addNotification('Prazo próximo de expiração!', contato.nome)
                        }
                    } else {
                        setAtencao(false)
                    }
                }
            }, 5000);

            return () => clearInterval(interval)
        }
    }, [dataExpiracao, dataLista, addNotification, atencao, atrasado, contato, produto])


    // console.log('cartao.data_lista: ', cartao.data_lista)
    // console.log('cartao.data_expiracao: ', cartao.data_expiracao)
    // console.log(`cartao.files.length: ${cartao.files.length}`)
    const agendar = ((lista.nome === 'Reunião Agendada') && (cartao.data_lista === cartao.data_expiracao))
    const valorar = ((lista.nome === 'Contrato Enviado') && (cartao.valor_contrato === '0.00'))
    const valorado = (cartao.valor_contrato !== '0.00')
    const temObs = (observacoes !== '')
    // console.log('agendar: ', agendar)
    // console.log('valorar: ', valorar)


    const handleFabClick = () => {
        setShowTextField(true);
    };

    async function setCard(card) {
        const cardAtualizado = await updCard(token, card).then(
            (response) => {
                return response
            },
            (erro) => {
                // console.log('Ocorreu um erro ao atualizar o card: ', erro)
                alert(`Ocorreu um erro ao atualizar o card: ${erro}`)
                return null
            }
        )

        return cardAtualizado
    }

    const handleSaveCloseClick = () => {
        cartao.observacoes = observacoes
        setCard(cartao)
        setShowTextField(false);
    };

    const handleCloseClick = () => {
        setObservacoes(cartao.observacoes)
        setShowTextField(false);
    }

    const handleObsChange = (e) => {
        setObservacoes(e.target.value)
    }

    const handleRatingChange = async (value) => {
        cartao.rating = value
        setRating(value)
        // eslint-disable-next-line
        const cardAtualizado = await setCard(cartao)
        // eslint-disable-next-line
        const listasAtualizadas = await fetchListas()
    }

    const handleAbordagensChange = async (value) => {
        cartao.abordagens = value
        setAbordagens(value)
        // console.log(`Abordagens: ${value}`)
        // eslint-disable-next-line
        const cardAtualizado = await setCard(cartao)
        // eslint-disable-next-line
        const listasAtualizadas = await fetchListas()
    }

    const handleDataReuniaoSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const date = data.get('date');
        const time = data.get('time');

        // Combine date and time to create a Date object
        const dateTimeString = `${date}T${time}`;
        const dateTime = new Date(dateTimeString);

        cartao.data_expiracao = dateTime
        setDataExpiracao(dateTime)
        setCard(cartao)
    };

    const handleValorFormSubmit = (event) => {
        event.preventDefault();

        cartao.valor_contrato = valorContrato
        setCard(cartao)
    }

    const handleValorFormChange = (value) => {
        setValorContrato(value.target.value)
    }

    const handleValorContratoChange = (value) => {
        setValorContrato(value)
    }

    const dataToShow = dataExpiracao.toLocaleString('pt-BR', { timezone: 'America/Sao_Paulo' })

    // const handleMouseOver = () => {
    //     setMouseOver(true)
    // }

    // const handleMouseOut = () => {
    //     setMouseOver(false)
    // }

    const handleDeleteClick = () => {
        setOpenDeleteDialog(true)
    }

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false)
    }

    const handleDeleteCard = async () => {
        const id = cartao.id
        cartao.status = 'I'
        // console.log('excluir cartão: ', id)
        // await delCard(id, token).then(
        //     (response) => {
        //         alert('Cartão excluído com sucesso!')
        //         setOpenDeleteDialog(false)
        //         fetchListas()
        //     },
        //     (erro) => {
        //         alert(`Ocorreu um erro ao excluir o cartão: ${erro.response.data}`)
        //         setOpenDeleteDialog(false)
        //     }
        // )

        await updCard(token, cartao).then(
            (response) => {
                alert('Cartão inativado com sucesso!')
                setOpenDeleteDialog(false)
                fetchListas()
            },
            (erro) => {
                alert(`Ocorreu um erro ao inativar o cartão: ${erro.response.data}`)
                setOpenDeleteDialog(false)
            }
        )
    }

    const handleUpload = async (dados) => {

        if (!selectedFile) return;

        const formData = new FormData()
        formData.append('file', selectedFile)
        formData.append('card', cartao.id)
        const dadosJson = Object.fromEntries(formData.entries())

        setUploading(true)
        setMessage('')
        setError(false)

        try {
            const response = await fileCardUpload(dadosJson, token)
            setMessage('Upload realizado com sucesso!')
        } catch (err) {
            console.log(err)
            setMessage('Erro ao realizar o upload.')
            setError(true)
        } finally {
            setUploading(false)
        }
    }

    const handleUploadClick = () => {
        // console.log('handleUploadClick')
        setShowUploadComponent(true)
        setMessage('')
        setError(false)
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
    };

    const handleUploadDialogClose = () => {
        setShowUploadComponent(false)
        setSelectedFile(null)
        fetchListas()
    }

    const disabled = (cartao.status === "I")
    // console.log('disabled: ', disabled)

    const getColor = () => {
        if (cartao.status === "I") {
            return "secondary"
        }
        if (atrasado) {
            return "red"
        }
        if (atencao) {
            return "yellow"
        }
        return "primary"
    }

    const getBorderColor = () => {
        return `2px solid ${getColor()}`
    }

    const handleCartaoDlgClose = () => {
        setCartaoDlgOpen(false)
    }

    // console.log('cartão: ', cartao)

    return (
        // <Paper elevation={6} ref={ref} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} sx={{
        <Paper elevation={6} ref={ref} sx={{
            padding: '0.5rem',
            backgroundColor: 'white',
            cursor: 'move',
            margin: 1,
            borderRadius: 2,
            transition: 'transform 0.2s ease-in-out',
            // border: atrasado ? '2px solid red' : (atencao ? '2px solid yellow' : '1px'),
            border: getBorderColor(),
        }}>
            {/* <ListItem ref={ref} sx={{
                padding: '0.5rem',
                backgroundColor: 'white',
                cursor: 'move',
                margin: 1,
                borderRadius: 2,
                transition: 'transform 0.2s ease-in-out',
                border: atrasado ? '2px solid red' : (atencao ? '2px solid yellow' : '1px'),
            }}> */}
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" >
                    {
                        contato ? (
                            <>
                                <Tooltip title={`whatsapp: ${contato.whatsapp} - email: ${contato.email}`} placement='top'>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} gutterBottom>
                                        {contato.nome}
                                    </Typography>
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title="Recuperando" placement='top'>
                                    <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }} gutterBottom>
                                        "Recuperando"
                                    </Typography>
                                </Tooltip>
                            </>
                        )
                    }
                    <IconButton color='primary' onClick={() => {setCartaoDlgOpen(true)}}>
                        <InfoIcon />
                    </IconButton>
                    <CartaoDlg open={cartaoDlgOpen} cartao={cartao} token={token} handleClose={handleCartaoDlgClose}/>
                </Box>
                <Box>
                    {agendar && (
                        <Box
                            component="form"
                            onSubmit={handleDataReuniaoSubmit}
                            sx={{
                                // mt: 2, 
                                // position: 'relative',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 2,
                                // width: 300,
                                margin: 'auto',
                                padding: 2,
                                border: '1px solid #ccc',
                                borderRadius: 2,
                            }}>
                            <TextField
                                id="date"
                                name="date"
                                label="Data"
                                type="date"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <TextField
                                id="time"
                                name="time"
                                label="Hora"
                                type="time"
                                size="small"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                            <Button type="submit" variant="contained" color="primary">
                                Agendar
                            </Button>
                        </Box>
                    )}
                    {
                        valorar && (
                            <Box
                                component='form'
                                onSubmit={handleValorFormSubmit}
                                sx={{
                                    mt: 2,
                                    mb: 2,
                                    // position: 'relative',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 2,
                                    // width: 300,
                                    // margin: 'auto',
                                    padding: 2,
                                    border: '1px solid #ccc',
                                    borderRadius: 2,
                                }}
                            >
                                <CurrencyTextField
                                    label='Valor do Contrato'
                                    value='0.00'
                                    variant='outlined'
                                    id='valor_contrato'
                                    onChange={handleValorFormChange}
                                    required
                                />
                                <Button type="submit" variant="contained" color="primary">
                                    Informar
                                </Button>
                            </Box>
                        )
                    }
                    <Typography variant="caption" sx={{ color: atencao ? 'black' : 'white' }} fontSize={12} gutterBottom>
                        {
                            atrasado ? (
                                <CardDateTimeIndicator type='danger' text={dataToShow} disabled={disabled} />
                            ) : (
                                <>
                                    {
                                        atencao ? (
                                            <CardDateTimeIndicator type='warning' text={dataToShow} disabled={disabled} />
                                        ) : (
                                            <CardDateTimeIndicator type='normal' text={dataToShow} disabled={disabled} />
                                        )
                                    }
                                </>
                            )
                        }
                    </Typography>
                </Box>
                <Box>
                    <IconButton color="black" aria-label="edit" onClick={handleFabClick} size="small" disabled={disabled}>
                        {temObs ? (
                            <>
                                <Tooltip title="Abrir observações" placement='top'>
                                    <CommentIcon sx={{ fontSize: 15 }} />
                                </Tooltip>
                            </>
                        ) : (
                            <>
                                <Tooltip title="Inserir observações" placement='top'>
                                    <AddCircleIcon sx={{ fontSize: 15 }} />
                                </Tooltip>
                            </>
                        )}
                    </IconButton>
                    {/* {
                        mouseOver && (
                            <> */}
                    <IconButton aria-label='delete' size='small' edge='end' onClick={handleDeleteClick} disabled={disabled}>
                        <>
                            <Tooltip title="Excluir cartão" placement='top'>
                                <DeleteIcon fontSize='inherit' />
                            </Tooltip>
                        </>
                    </IconButton>

                    {/* </>
                        )
                    } */}
                    <IconButton aria-label='upload' size='small' edge='end' onClick={handleUploadClick} disabled={disabled}>
                        <Tooltip title="Anexar arquivo" placement='top'>
                            <UploadFileIcon fontSize='inherit' />
                        </Tooltip>
                    </IconButton>
                    <Dialog open={showUploadComponent} onClose={handleUploadDialogClose} maxWidth="sm" fullWidth>
                        <DialogTitle>Anexar Arquivo</DialogTitle>
                        <DialogContent>
                            <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<CloudUploadIcon />}
                                    sx={{ mb: 2 }}
                                >
                                    Selecionar Arquivo
                                    <input type='file' hidden onChange={handleFileChange} />
                                </Button>

                                {
                                    selectedFile && (
                                        <Typography variant='body1'>
                                            {`Arquivo selecionado: ${selectedFile.name}`}
                                        </Typography>
                                    )
                                }
                            </Box>

                            {
                                message && (
                                    <Typography
                                        variant='body1'
                                        color={error ? 'error' : 'success'}
                                        sx={{ mt: 2, textAlign: 'center' }}
                                    >
                                        {message}
                                    </Typography>
                                )
                            }
                        </DialogContent>

                        <DialogActions>
                            <Box display="flex" justifyContent="space-between" width="100%" px={2}>
                                <Button onClick={handleUploadDialogClose} color='secondary'>
                                    Fechar
                                </Button>

                                <Button
                                    onClick={handleUpload}
                                    color='primary'
                                    variant='contained'
                                    disabled={!selectedFile || uploading}
                                    startIcon={uploading && <CircularProgress size={20} />}
                                >
                                    {uploading ? 'Enviando...' : 'Enviar'}
                                </Button>
                            </Box>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        fullScreen={fullScreen}
                        open={openDeleteDialog}
                        onClose={handleCloseDeleteDialog}
                        aria-labelledby='delete-dialog-title'
                    >
                        <DialogTitle id="delete-dialog-title">
                            {"Confirma a inativação do cartão?"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Ao confirmar a inativação do cartão, ele será retirado do funil. Você poderá visualizá-lo depois nos cards inativos.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleCloseDeleteDialog}>
                                Cancelar
                            </Button>
                            <Button onClick={handleDeleteCard} autoFocus>
                                Confirmar
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {showTextField && (
                        <Box sx={{ mt: 2, position: 'relative' }}>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                // placeholder="Insira seu texto aqui..."
                                variant="outlined"
                                value={observacoes}
                                onChange={handleObsChange}
                                sx={{ fontSize: 10 }}
                            />
                            <IconButton
                                aria-label="close"
                                size='small'
                                color='red'
                                onClick={handleCloseClick}
                            // sx={{ position: 'relative', top: 8, right: 8 }}
                            >
                                <Tooltip title='Fechar sem salvar' placement='bottom'>
                                    <CloseIcon fontSize='inherit' sx={{ color: 'red' }} />
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                aria-label="close"
                                size='small'
                                color='blue'
                                onClick={handleSaveCloseClick}
                            // sx={{ position: 'relative', top: 8, right: 8 }}
                            >
                                <Tooltip title='Salvar e fechar' placement='bottom'>
                                    <CheckIcon fontSize='inherit' sx={{ color: 'blue' }} />
                                </Tooltip>
                            </IconButton>
                        </Box>
                    )}
                    {
                        valorado && (
                            <Container>
                                <CurrencyEditableLabel initialText={valorContrato} onChange={handleValorContratoChange} />
                            </Container>
                        )
                    }
                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                        <StarRating starColor='gold' stars={rating} onRatingChange={handleRatingChange} disabled={disabled} />
                        {
                            contato && (
                                <Tooltip title="Chamar no Whatsapp" placement='bottom'>
                                    <WhatsAppLink phoneNumber={contato.whatsapp} />
                                </Tooltip>
                            )
                        }
                    </Box>
                    {
                        lista.mostra_abordagens && (
                            <Box display='flex' justifyContent='space-between' alignItems='center' >
                                <Typography>Abordagens:</Typography>
                                <CheckListAbordagem abordagens={abordagens} onAbordagensChange={handleAbordagensChange} disabled={disabled} />
                            </Box>
                        )
                    }
                    {
                        produto && (
                            <Box display='flex' justifyContent='center' alignItems='center' sx={{ backgroundColor: 'blue', margin: 1, padding: 1 }}>
                                <Tooltip title={produto.nome}>
                                    <Typography
                                        fontWeight='bold'
                                        fontStyle='italic'
                                        color='white'
                                    >
                                        {produto.tag}
                                    </Typography>
                                </Tooltip>
                            </Box>
                        )
                    }
                </Box>
            </Box>
            {
                (cartao.files.length > 0) && (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ArrowDropDownIcon />}
                            aria-controls='anexos-content'
                            id='anexos-header'
                        >
                            <Typography>Anexos</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {
                                <List>
                                    {
                                        cartao.files.map((file, index) => (
                                            <ListItem key={index}>
                                                <Link href={`${baseAPI}${file.file_url}`} target="_blank" rel="noopener noreferrer">
                                                    <ListItemText primary={file.file_url.split('/').pop()} />
                                                </Link>
                                            </ListItem>
                                        ))
                                    }
                                </List>

                            }
                        </AccordionDetails>
                    </Accordion>
                )
            }
        </Paper>
    );
};

export default Cartao