import { Phone } from "@mui/icons-material";
import { TextField } from "@mui/material";
import React from "react";

const CelularEdit = ({ id, name, label, variant = 'outlined', required = false, value = '', onChange, ...other }) => {
    // const [text, setText] = useState(value)

    const handleChange = (event) => {
        const val = event.target.value || ''
        const cleanedVal = val.replace(/\D/g, '')
        // console.log(`cleanedVal: ${cleanedVal}`)

        let num = cleanedVal

        if (cleanedVal !== '') {
            num = `(${cleanedVal.substring(0, 2)}) ${cleanedVal.substring(2, 7)}-${cleanedVal.substring(7, 11)}`.trim()
        }

        // setText(num)
        // console.log(`${name}: ${num}`)

        if (onChange) {
            const newEvent = {
                target: {
                    name: name,
                    value: num
                }
            }
            onChange(newEvent)
        }
    }

    return (
        <TextField
            id={id}
            name={name}
            label={label}
            variant={variant}
            value={value}
            required={required}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <Phone sx={{ color: 'action.active', mr: 1 }} />
                ),
            }}
            {...other}
        />
    )
}

export default CelularEdit;