import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { newProduto, updProduto } from "../../services/srvProduto";

export default function ProdutoDlg({ produto = null, perfil, chave, show, handleClose, fetchProdutos }) {

    const [produtoMsg, setProdutoMsg] = useState('')
    const [produtoErro, setProdutoErro] = useState(false)
    const [salvandoProduto, setSalvandoProduto] = useState(false)
    const [produtoTag, setProdutoTag] = useState('')
    const [produtoNome, setProdutoNome] = useState('')

    useEffect(() => {
        if (produto) {
            setProdutoTag(produto.tag)
            setProdutoNome(produto.nome)
        } else {
            setProdutoTag('')
            setProdutoNome('')
        }
    }, [show, produto])

    const handleDlgClose = () => {
        setProdutoMsg('')
        setProdutoTag('')
        setProdutoNome('')
        handleClose()
    }

    const handleTagChange = (event) => {
        setProdutoTag(event.target.value.toUpperCase())
    }

    const handleNomeChange = (event) => {
        setProdutoNome(event.target.value)
    }

    return (
        <Dialog
            open={show}
            PaperProps={{
                component: 'form',
                onSubmit: async (event) => {
                    event.preventDefault()
                    setSalvandoProduto(true)
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries(formData.entries());
                    formJson['assinante'] = perfil.assinante

                    if (produto) {
                        formJson['id'] = produto.id
                        await updProduto(formJson, chave).then(
                            (response) => {
                                setProdutoErro(false)
                                setProdutoMsg('Produto atualizado com sucesso!')
                                fetchProdutos()
                                setSalvandoProduto(false)
                            },
                            (erro) => {
                                setProdutoErro(true)
                                setProdutoMsg(`Ocorreu um erro um atualizar o produto: ${erro}`)
                                setSalvandoProduto(false)
                            }
                        )
                    } else {
                        await newProduto(formJson, chave).then(
                            (response) => {
                                setProdutoErro(false)
                                setProdutoMsg('Produto salvo com sucesso!')
                                fetchProdutos()
                                setSalvandoProduto(false)
                            },
                            (erro) => {
                                setProdutoErro(true)
                                setProdutoMsg(`Ocorreu um erro ao salvar o produto: ${erro}`)
                                setSalvandoProduto(false)
                            }
                        )
                    }

                    setTimeout(() => {
                        handleDlgClose()
                    }, 5000);
                }
            }}
            onClose={handleDlgClose}
        >
            <DialogTitle>{produto ? 'Alterar produto' : 'Novo Produto'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="tag"
                    name="tag"
                    label="Tag do Produto"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={produtoTag}
                    onChange={handleTagChange}
                    inputProps={{ maxLength: 15 }}  // Limita a quantidade de caracteres
                    helperText={`${produtoTag.length}/15 caracteres`}
                />
                <TextField
                    required
                    margin="dense"
                    id="nome"
                    name="nome"
                    label="Nome do Produto"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={produtoNome}
                    onChange={handleNomeChange}
                    inputProps={{ maxLength: 50 }}  // Limita a quantidade de caracteres
                    helperText={`${produtoNome.length}/50 caracteres`}

                />
                {
                    produtoMsg && (
                        <Typography
                            variant='body1'
                            color={produtoErro ? 'error' : 'success'}
                            sx={{ mt: 2, textAlign: 'center' }}
                        >
                            {produtoMsg}
                        </Typography>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDlgClose}>Cancelar</Button>
                <Button
                    type="submit"
                    color='primary'
                    variant='contained'
                    // disabled={!selectedFile || uploading}
                    startIcon={salvandoProduto && <CircularProgress size={20} />}
                >
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
